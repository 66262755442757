export const Main_carousel = [
  {
    id: 0,
    img: process.env.PUBLIC_URL + "/images/slider-1.jpg",
    tagline: "Join Us Today",
    title: "Together we can make a Difference",
    desc: " Help today because tomorrow you may be the one who needs helping! Forget what you can get and see what you can give.",
  },
  {
    id: 1,
    img: process.env.PUBLIC_URL + "/images/slider-2.jpg",
    title: "Better Life for People",
    desc: " Help today because tomorrow you may be the one who needs helping! Forget what you can get and see what you can give.",
  },
  {
    id: 2,
    img: process.env.PUBLIC_URL + "/images/slider-3.jpg",
    title: "Give a little.Change a lot.",
    desc: " Help today because tomorrow you may be the one who needs helping! Forget what you can get and see what you can give.",
  },
];

export const news_data = [
  {
    id: 0,
    news_title: "Lorem Ipsum News 1",
    link_title: "Read More",
    date: "2023-11-10",
  },
  {
    id: 1,
    news_title: "Lorem Ipsum News 2",
    link_title: "Read More",
    date: "2023-11-11",
  },
  {
    id: 2,
    news_title: "Lorem Ipsum News 3",
    link_title: "Read More",
    date: "2023-11-12",
  },
  {
    id: 3,
    news_title: "Lorem Ipsum News 4",
    link_title: "Read More",
    date: "2023-11-13",
  },
  {
    id: 4,
    news_title: "Lorem Ipsum News 5",
    link_title: "Read More",
    date: "2023-11-14",
  },
  {
    id: 5,
    news_title: "Lorem Ipsum News 6",
    link_title: "Read More",
    date: "2023-11-15",
  },
  {
    id: 6,
    news_title: "Lorem Ipsum News 7",
    link_title: "Read More",
    date: "2023-11-16",
  },
  {
    id: 7,
    news_title: "Lorem Ipsum News 8",
    link_title: "Read More",
    date: "2023-11-17",
  },
  {
    id: 8,
    news_title: "Lorem Ipsum News 9",
    link_title: "Read More",
    date: "2023-11-18",
  },
  {
    id: 9,
    news_title: "Lorem Ipsum News 10",
    link_title: "Read More",
    date: "2023-11-19",
  },
];

export const promo_Options = [
  {
    id: 0,
    img: process.env.PUBLIC_URL + "/images/icon-1.png",
    title: "Make Donation",
    desc: "Your donation will make a difference to so many people in the community and help create a brighter, more prosperous future for everyone.",
  },
  {
    id: 1,
    img: process.env.PUBLIC_URL + "/images/icon-2.png",
    title: "Fundraising",
    desc: "It takes a lot of dedication, hard work and initiative to make your fundraising campaign a success. That's why we need your help in fundraising for our charity.",
  },
  {
    id: 2,
    img: process.env.PUBLIC_URL + "/images/icon-3.png",
    title: "Become A Volunteer",
    desc: "Volunteering with our charity is an incredibly rewarding experience. You can witness first-hand the difference that can be made by volunteering.",
  },
];

export const menuItems = [
  
  {
    route: "Home",
    target: "main",
  },{
    route: "About",
    target: "about",
  },
  {
    route: "Missions",
    target: "mission",
  },
  {
    route: "Team",
    target: "team",
  },
  {
    route: "Volunteers",
    target: "volunteers",
  },
  {
    route: "Campaigns",
    target: "campaigns",
  },
];

export const future_campaigns = [
  {
    id: 0,
    img: process.env.PUBLIC_URL + "/images/post-1.jpg",
    title: "Card Title 1",
    desc: " Help today because tomorrow you may be the one who needs helping! Forget what you can get and see what you can give.",
  },
  {
    id: 1,
    img: process.env.PUBLIC_URL + "/images/post-2.jpg",
    title: "Card Title 2",
    desc: " Help today because tomorrow you may be the one who needs helping! Forget what you can get and see what you can give.",
  },
  {
    id: 2,
    img: process.env.PUBLIC_URL + "/images/post-3.jpg",
    title: "Card Title 3",
    desc: " Help today because tomorrow you may be the one who needs helping! Forget what you can get and see what you can give.",
  },
  {
    id: 3,
    img: process.env.PUBLIC_URL + "/images/post-4.jpg",
    title: "Card Title 4",
    desc: " Help today because tomorrow you may be the one who needs helping! Forget what you can get and see what you can give.",
  },
  {
    id: 4,
    img: process.env.PUBLIC_URL + "/images/post-5.jpg",
    title: "Card Title 5",
    desc: " Help today because tomorrow you may be the one who needs helping! Forget what you can get and see what you can give.",
  },
  {
    id: 5,
    img: process.env.PUBLIC_URL + "/images/post-6.jpg",
    title: "Card Title 6",
    desc: " Help today because tomorrow you may be the one who needs helping! Forget what you can get and see what you can give.",
  },
  {
    id: 6,
    img: process.env.PUBLIC_URL + "/images/post-1.jpg",
    title: "Card Title 7",
    desc: " Help today because tomorrow you may be the one who needs helping! Forget what you can get and see what you can give.",
  },
  {
    id: 7,
    img: process.env.PUBLIC_URL + "/images/post-2.jpg",
    title: "Card Title 8",
    desc: " Help today because tomorrow you may be the one who needs helping! Forget what you can get and see what you can give.",
  },
  {
    id: 8,
    img: process.env.PUBLIC_URL + "/images/post-3.jpg",
    title: "Card Title 9",
    desc: " Help today because tomorrow you may be the one who needs helping! Forget what you can get and see what you can give.",
  },
  {
    id: 9,
    img: process.env.PUBLIC_URL + "/images/post-4.jpg",
    title: "Card Title 10",
    desc: " Help today because tomorrow you may be the one who needs helping! Forget what you can get and see what you can give.",
  },
  {
    id: 10,
    img: process.env.PUBLIC_URL + "/images/post-5.jpg",
    title: "Card Title 11",
    desc: " Help today because tomorrow you may be the one who needs helping! Forget what you can get and see what you can give.",
  },
  {
    id: 11,
    img: process.env.PUBLIC_URL + "/images/post-6.jpg",
    title: "Card Title 12",
    desc: " Help today because tomorrow you may be the one who needs helping! Forget what you can get and see what you can give.",
  },
  {
    id: 12,
    img: process.env.PUBLIC_URL + "/images/post-2.jpg",
    title: "Card Title 13",
    desc: " Help today because tomorrow you may be the one who needs helping! Forget what you can get and see what you can give.",
  },
  {
    id: 13,
    img: process.env.PUBLIC_URL + "/images/post-3.jpg",
    title: "Card Title 14",
    desc: " Help today because tomorrow you may be the one who needs helping! Forget what you can get and see what you can give.",
  },
];

export const volunteers = [
  {
    id: 0,
    img: process.env.PUBLIC_URL + "/images/team-2.jpg",
    name: "Dr.Aadil Bashir Wani",
    qual: "Sr.Assistant Professor Social work University of Kashmir Child Rights Expert",
  },
  {
    id: 1,
    img: process.env.PUBLIC_URL + "/images/team-3.png",
    name: "Dr.Faroze Ahmad Khan",
    qual: "Gastroenterologist Health Deptt. J&K Health Expert",
  },
  {
    id: 2,
    img: process.env.PUBLIC_URL + "/images/team-4.jpg",
    name: "Alfred Gnanaolivu",
    qual: "Consultant at Freelance Consultancy & Training Eco Foundation for Social Alternative EFSA) Bengaluru,Karnataka, India",
  },
  {
    id: 3,
    img: process.env.PUBLIC_URL + "/images/team-5.jpg",
    name: "Ms.Affarmand Rashid",
    qual: "Assistant Director incharge of SOS children’s village of Srinagar. Experienced Developmental Practitioner on Child Rights, Women Empowerment and Youth Development.",
  },
  {
    id: 4,
    img: process.env.PUBLIC_URL + "/images/team-6.jpg",
    name: "Mr.Nasir Rahim",
    qual: "Senior Child Rights Practitioner and Youth Development Expert. SOS Children’s Village of India.",
  },
  {
    id: 5,
    img: process.env.PUBLIC_URL + "/images/team-7.jpg",
    name: "Dr.Basharat Ahmad Bhat",
    qual: "PhD Life Sciences University of Kashmir",
  },
];

export const team_members = [
  {
    id: 0,
    img: process.env.PUBLIC_URL + "/images/team-01.jpg",
    name: "Dr.Shabir Ahmad Najar ",
    designation: "Vice-Chairman",
    title: "Specialization-Unorganized Sector",
    qual: "B.A, B.Ed., M.A, PhD.UGC- NET,SET, JRF",
  },
  {
    id: 1,
    img: process.env.PUBLIC_URL + "/images/team-02.jpg",
    name: "Mr.Zahid Ahmad Lone",
    designation: "Coordinator",
    title: "Specialization-Disability studies.",
    qual: "MSW,PhD(Pursuing)UGC-NET",
  },
  {
    id: 2,
    img: process.env.PUBLIC_URL + "/images/team-03.jpg",
    name: "Ms.Faryalla Farooq",
    designation: "Spokesperson",
    title: "Specialization- Working With Children, Youth",
    qual: "B.A,MSW",
  },
  {
    id: 3,
    img: process.env.PUBLIC_URL + "/images/team-03.jpg",
    name: "Ms.Seerat Jan",
    designation: "Organizer",
    title: "Specialization- Working With Women, Elderly",
    qual: "B.A, MSW",
  },
  {
    id: 4,
    img: process.env.PUBLIC_URL + "/images/team-05.jpg",
    name: "Mr.Anzar Ul Aijaz",
    designation: "Member",
    title: "Specialization-Counselling Skills in Drug De-Addiction",
    qual: " MSW (Pursuing) Drug De-Addiction (6 months certificate course)-completed",
  },
  {
    id: 5,
    img: process.env.PUBLIC_URL + "/images/team-06.jpeg",
    name: "Mr.Danish Iqbal mattoo",
    designation: "Program Manager/coordinator",
    title: "Specialization-HR & Finance",
    qual: "MBA, MPhil",
  },  {
    id: 6,
    img: process.env.PUBLIC_URL + "/images/team-07.jpeg",
    name: "Er. Mohammad Faizan Bhat",
    designation: "State coordinator",
    title: "Specialization-IT",
    qual: "BTech (NIT)",
  },
];

